.forecaste_card {
  padding-left: 40px !important;
}
.icon {
  font-size: 20px;
}
.icon-l {
  font-size: 32px;
}
.f_header {
  padding-left: 20px;
}
.f_header h5 {
  font-size: 20px;
}
.f_content h5 {
  margin: 5px;
}

.f_content {
  position: relative;
  padding: 5px 40px 20px 40px;
  z-index: 11;
  opacity: 0.6;
  background-color: rgba($color: #eee, $alpha: 0.6);
  mix-blend-mode: normal;
}
.f_content span {
  margin-right: 10px;
}
.f_content span.temp {
  font-size: 30px;
}
.f_content span.hum {
  font-size: 30px;
}
small.units {
  font-size: 15px;
}

@media (max-width: 991px) {
  .f_content span.temp {
    font-size: 25px;
  }
  .f_content span.hum {
    font-size: 25px;
  }
}
