#building {
  display: flex;
  width: 20em;
  /* height: 100vh; */
  margin: auto;
}
#blocks {
  margin: auto;
}
#caption {
  padding-left: 0.5em;
  margin: auto;
  font-size: 0.7em;
  font-family: "century gothic";
}
.b {
  /* background: repeating-linear-gradient(120deg, #22bc97 10px, #1abfd4 10px); */
  background: repeating-linear-gradient(
    45deg,
    #3ac5a5,
    #3ac5a5 5px,
    #22bc97 5px,
    #22bc97 10px
  );

  background: var(--theme-color);
  /* box-shadow: 5px 5px 15px #b3ffed, inset 5px 5px 20px #b3ffed44; */
  /* border: 2px solid #22bc97; */
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 10%;
  margin: 1em;
  position: relative;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.textAnimation {
  font-weight: bold;
  display: inline-block;
  background: linear-gradient(
    to right,
    rgb(255, 164, 90) 40%,
    rgb(245, 87, 81) 50%,
    rgb(248, 103, 147) 60%,
    rgb(207, 95, 241) 80%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;
  @keyframes shine {
    0% {
      background-position: 0% center;
    }
    100% {
      background-position: 100% center;
    }
  }
}

.b[theme="blue"] {
  background: var(--theme-color);
}

#b1 {
  animation-name: b1;
}
#b2 {
  animation-name: b2;
  margin-left: 7.25rem;
}
#b3 {
  animation-name: b3;
  margin-top: -6rem;
}
#b4 {
  animation-name: b4;
  margin-left: 7.25rem;
}
@keyframes b1 {
  0% {
    left: 0rem;
    transform: rotate(0deg);
    transition: 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  50% {
    left: 6.25rem;
    bottom: 0rem;
    transform: rotate(90deg);
    transition: 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  100% {
    left: 6.25rem;
    bottom: -6rem;
    transform: rotate(90deg);
    transition: 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
}
@keyframes b2 {
  50% {
    bottom: 0rem;
  }
  100% {
    bottom: -6rem;
  }
}
@keyframes b3 {
  50% {
    top: 0rem;
  }
  100% {
    top: -6rem;
  }
}
@keyframes b4 {
  0% {
    left: 0rem;
    transform: rotate(0deg);
    transition: 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  50% {
    left: -6.25rem;
    top: 0rem;
    transform: rotate(90deg);
    transition: 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  100% {
    left: -6.25rem;
    top: -6rem;
    transform: rotate(90deg);
    transition: 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
}
@media (max-width: 400px) {
  #building {
    width: 100%;
  }
  #caption {
    display: none;
  }
}
