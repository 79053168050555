.MuiTableContainer-root::-webkit-scrollbar {
  opacity: 0.4;
  width: 0.4em;
  height: 0.4em;
}
.MuiTableContainer-root::-webkit-scrollbar-track {
  width: 0.5em;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e6effe;
  border-radius: 5px;
}

.MuiTableContainer-root::-webkit-scrollbar-track:horizontal {
  height: 0.5em;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(88, 88, 88, 0.4)),
    color-stop(0.72, rgba(64, 68, 94, 0.4)),
    color-stop(0.86, rgba(68, 68, 68, 0.4))
  );
}

.MuiTableCell-head {
  position: sticky;
  top: 0;
  /* top: 0;
  left: 0;
  right: 0; */
}
