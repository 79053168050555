@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}
.all-icons input {
  border: 0;
}
.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}
.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

#map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}

.places-buttons .btn {
  margin-bottom: 30px;
}
.sidebar .nav {
  list-style-type: none;
  padding-inline-start: 10px;
}

.sidebar .nav li {
}

.sidebar .nav li a {
  text-decoration: none;
  padding: 10px 5px;
}
.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

@media all and (max-width: 991px) {
  .sidebar .nav > li.active-pro {
    position: initial;
  }
}
