*{
  box-sizing: border-box;
}

// body {
//   font-family: Roboto;
//   background: #e9e9e9;
// }
.btn_con {
  // margin: 5px;
  margin-top: 20px;
  float: right;
}
.btn_con button {
  position: relative;
  border: none;
  outline: none;
  // background-color: rgba($color: #5099f8, $alpha: 1);
  color: white;
  border-radius: 5px 0 0 5px;
  padding: 5px 20px;
  font-family: "Comfortaa", cursive;

  background: linear-gradient(to top left, #6cbff7, #4e70e2);
  //transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 4px 4px 12px rgba($color: rgb(82, 164, 231), $alpha: 0.3),
    8px 8px 10px rgba($color: rgb(128, 219, 247), $alpha: 0.42);
  transition: 0.3s ease-in-out;

  &:hover {
    top: 1px;
    left: 1px;
    box-shadow: none;
    transition: 0.3s ease-in-out;
  }
  &:disabled {
    background-color: rgba($color: #4e4e4e, $alpha: 1);
    box-shadow: none;
  }
}

.btn_con.green button {
  background: linear-gradient(to top left, #21bc96, #19aa86);
  //transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 4px 4px 12px rgba($color: #21bc96, $alpha: 0.3),
    8px 8px 10px rgba($color: #70ffdb, $alpha: 0.32);
  &:hover {
    top: 1px;
    left: 1px;
    box-shadow: none;
    transition: 0.3s ease-in-out;
  }
}
.btn_con.purple button {
  background: linear-gradient(to top left, #be9cfd, #9366e9);
  //transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 4px 4px 12px rgba($color: #9366e9, $alpha: 0.2),
    8px 8px 10px rgba($color: #9366e9, $alpha: 0.22);
  border-radius: 0 5px 5px 0;
  &:hover {
    top: 1px;
    left: 1px;
    box-shadow: none;
    transition: 0.3s ease-in-out;
  }
}

.text_highlight {
  // text-align: center;
  font-weight: bold;
  display: inline-block;
  background: linear-gradient(
    to right,
    rgb(175, 90, 255) 40%,
    rgb(142, 201, 255) 50%,
    rgb(103, 125, 248) 60%,
    rgb(95, 141, 241) 80%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;
  @keyframes shine {
    0% {
      background-position: 0% center;
    }
    100% {
      background-position: 100% center;
    }
  }
}

.radio_con {
  display: inline-block;
  background: var(--background);
  margin: 5px;
  margin-top: 20px;
  border-top: 2px solid #17b384;
  box-shadow: 0 2px 8px rgba(#000, 0.2);
  overflow: hidden;
  position: relative;
  font-size: 0;
  text-align: left;
  white-space: nowrap;
  border-radius: 2px;
  cursor: pointer;

  &:hover > .current > i {
    color: #777 !important;
  }
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    background: #17b384;
    color: #fff !important;
    height: 10%;
    width: 10%;
    font-size: 1.2rem;
    min-width: 4.25em;
    z-index: 3;
    transition: 0.3s;
    border-radius: 0 0 5px 5px;
  }
}

.fileType {
  font-family: "Comfortaa", cursive;
  width: 10%;
  min-width: 4.25em;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.75em 1em;
  cursor: pointer;
  position: relative;
  z-index: 5;
  transition: 0.3s;
  &:nth-child(1).current ~ .slider {
    transform: translateX(0);
  }
  &:nth-child(2).current ~ .slider {
    transform: translateX(100%);
  }
  &:nth-child(3).current ~ .slider {
    transform: translateX(200%);
  }

  &:nth-child(1):hover ~ .slider {
    transform: translateX(0);
  }
  &:nth-child(2):hover ~ .slider {
    transform: translateX(100%);
  }
  &:nth-child(3):hover ~ .slider {
    transform: translateX(200%);
  }

  &:hover {
    color: #fff;
  }
  &.current {
    color: #fff;
    &:hover {
      color: #fff !important;
    }
  }
}

// Simplifiies On Phones

// @media (max-width: 667px) {
//   .radio_con {
//     overflow-x: hidden;
//     &:hover .current {
//       color: #fff !important;
//     }
//   }

//   .fileType {
//     &.current {
//       background: #17b384;
//     }
//     &:not(.current):hover {
//       color: #000;
//     }
//   }

//   .slider {
//     display: none;
//   }
// }

.rlc-calendar {
  position: relative;
  z-index: 13;
  background-color: #fff;
  width: 220px !important;
  height: 200px;
}

.b_Content .b_header {
  padding: 10px;
}
.b_Content .b_header h5 {
  font-size: 20px;
}

.RangeExample {
  position: relative;
  border-radius: 10px;
  background-color: rgba($color: var(--background), $alpha: 0.25);
  padding: 10px;
  text-align: center;
}
.select-wrapper {
  text-align: right;
}

.deviceIdSelect__reports {
  margin: 10px 0 10px 5px;
  background-color: var(--background);
  padding: 10px 15px;
  box-shadow: 2px 2px 5px var(--box-shadow), -2px -2px 5px var(--box-shadow-inv);
  border-radius: 5px;
  overflow: hidden;
  text-align: right;
  width: 45%;
  display: inline-block;
}

.deviceIdSelect__reports select {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.resetbtn {
  // background-color: #e4ca82;
  // border-radius: 50%;
  // width: 40px;
  // height: 40px;
  position: relative;
  display: inline-block;
  color: #107c65;
  border: none;
  outline: none;
  margin-left: 10px;
  color: #fff;
  background: linear-gradient(
    to bottom right,
    rgb(235, 203, 100),
    rgb(245, 240, 170)
  );
  padding: 4px;
  font-size: 12px;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 10px;
  cursor: pointer;
  padding: 2px 10px;
  box-shadow: 2px 4px 4px rgba($color: rgb(243, 213, 116), $alpha: 0.3),
    4px 8px 8px rgba($color: rgb(235, 203, 100), $alpha: 0.4);
  border: 1px;
  background-clip: padding-box; /* !importanté */
  // border: solid 0.5px rgba(245, 240, 170, 0.7); /* !importanté */
  border-radius: 1em;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: -1;
  //   margin: -4px; /* !importanté */
  //   border-radius: 10px; /* !importanté */
  //   background:
  // }
}
.resetbtn:hover {
  top: 1px;
  left: 1px;
  box-shadow: none;
  transition: 0.4s ease-out;
}

.resetbtn span {
  display: none;
  font-size: 14px;
}

// .resetbtn:hover span {
//   display: inline;
//   // transition: 0.3s ease-in-out;
//   font-size: 12px;
// }

.b_footer {
  position: relative;
  padding: 0 15px;
}

.DayPicker.Selectable {
  // position: relative;
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
  left: 2%;
  font-size: 1.2rem;
  // transform: translateX(-2.5%);
}

@media (max-width: 991px) {
  #chartdiv {
    text-align: center;
  }

  .RangeExample {
    width: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .btn_con {
    left: 9%;
  }
  .radio_con {
    width: 60%;
  }

  // .DayPicker {
  //   position: relative;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
}
@media (min-width: 768px) {
  .btn_con {
    left: 14%;
    // background-color: #000;
  }
  .DayPicker.Selectable {
    position: relative;
    // left: 8%;
    font-size: 0.9rem;
  }
}
@media (min-width: 1990px) {
  .btn_con {
    left: 18%;
    // background-color: #000;
  }
  .DayPicker.Selectable {
    position: relative;
    left: 1.5%;
    font-size: 1rem;
  }
}
