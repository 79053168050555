/* Center tables for demo */
table {
    margin: 0 auto;
  }
  
  /* Default Table Style */
  table {
    color: #000;
    /* background: white; */
    /* border: 1px solid grey; */
    font-size: 12pt;
    border-collapse: collapse;
    border-radius: 10px;
  }
  table thead th,
  table tfoot th {
    color: #000;
    font-weight: bolder;
    background: #87bcf1;
   
  }


  table thead th{
    position: sticky;
    top: -20px;
    z-index: 20;
  }


  table caption {
    padding:.5em;
  }
  table th,
  table td {
    padding: .5em;
    border: 1px solid lightgrey;
  }
  /* Zebra Table Style */
  [data-table-theme*=zebra] tbody tr:nth-of-type(odd) {
    background: rgba(0,0,0,.05);
  }
  [data-table-theme*=zebra][data-table-theme*=dark] tbody tr:nth-of-type(odd) {
    background: rgba(255,255,255,.05);
  }
  /* Dark Style */
  [data-table-theme*=dark] {
    color: #ddd;
    background: #000;
    font-size: 12pt;
    border-collapse: collapse;
  }
  [data-table-theme*=dark] thead th,
  [data-table-theme*=dark] tfoot th {
    color: #000;
    background: rgba(0255,255,255,.15);
  }
  [data-table-theme*=dark] caption {
    padding:.5em;
  }
  [data-table-theme*=dark] th,
  [data-table-theme*=dark] td {
    padding: .5em;
    border: 1px solid grey;
  }

  th:hover button{
    visibility: visible;
  }
  th button{
    visibility: hidden;
  }