.wrapper {
  //filter: blur(50px);
}
.closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 2px;
  color: white;
  cursor: pointer;
}
// .wrapper {
//   transition: 10s;
//   filter: blur(25px);
// }
.msg_container {
  position: absolute;
  top: 2%;
  right: 2%;
  transform: translate(-10%, 22%);
  border-radius: 15px;

  z-index: 100;
  padding: 10px;
  filter: blur(0);
  min-width: 350px;
  width: 380px;
}

.msg_container[themeColor="red"] {
  background-color: rgba($color: #fa557e, $alpha: 0.95);
  box-shadow: 03px 3px 5px rgba(241, 3, 102, 0.15),
    4px 6px 8px rgba(248, 83, 124, 0.2), 6px 12px 12px rgba(241, 5, 96, 0.21);
  border: 5px solid rgba(121, 7, 35, 0.1);
}

.msg_container[themeColor="blue"] {
  background-color: rgba($color: #55b0fa, $alpha: 0.95);
  box-shadow: 03px 3px 5px rgba(85, 209, 247, 0.15),
    4px 6px 8px rgba(83, 174, 248, 0.2), 6px 12px 12px rgba(70, 199, 250, 0.21);
  border: 5px solid rgba(113, 224, 252, 0.1);
}
.msg_container[themeColor="green"] {
  background-color: rgba($color: #03e7ae, $alpha: 0.95);
  box-shadow: 03px 3px 5px rgba(37, 168, 125, 0.15),
    4px 6px 8px rgba(78, 247, 182, 0.2), 6px 12px 12px rgba(70, 250, 205, 0.21);
  border: 5px solid rgba(30, 250, 177, 0.1);

  & .msg_container .row .col-md-12 .msg_header {
    color: black;
  }
}

.msg_container[isavailable="false"] {
  display: none;
}
.msg_container[isavailable="true"] {
  display: block;
}
.msg_container[data-color="dark"] {
  background-color: #222;
  color: white;
}

.msg_container[data-color="light"] {
  background-color: #eee;
  color: #222;
}
.msg_container[data-color="light"] .msg_header,
.msg_container[data-color="light"] .msg_body {
  //background-color: #eee;
  color: #222;
}

.msg_header {
  font-size: 24px;
  color: rgb(63, 62, 62);
  font-weight: bold;
  //   text-align: center;
}
.msg_body {
  color: rgb(51, 51, 51);
}

.msg_icon {
  width: 80px;
  height: 80px;
  padding-left: 0px;
  padding-right: 0px;
  position: absolute;
  right: 0;
  // background-color: white;
  // border-radius: 5px;
}
