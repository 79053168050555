.color-pallet {
  height: 20px !important;
  width: 20px !important;
  background-color: blanchedalmond;
  border-radius: 50%;
  display: inline-block;
  margin: 3px;
  // border: solid 1px white;
  box-shadow: 0 2px 8px rgba(#000, 0.2);
}
.color-pallet.active {
  border: dotted 2px rgb(245, 238, 238);
}

.color-pallet[data-color="Red-Light"] {
  background-color: rgb(194, 2, 2);
}
.color-pallet[data-color="Blue-Light"] {
  background-color: rgb(34, 101, 247);
}
.color-pallet[data-color="Green-Light"] {
  background-color: rgb(16, 221, 95);
}
.color-pallet[data-color="Purple-Light"] {
  background-color: #9350e9;
}
.color-pallet[data-color="Orange-Light"] {
  background-color: rgb(248, 122, 19);
}
.color-pallet[data-color="Azure-Light"] {
  background-color: rgb(18, 193, 247);
}
.color-pallet[data-color="Grey-Light"] {
  background-color: #373737;
}
.color-pallet[data-color="Leaf-Light"] {
  background-color: #15846d;
}
.color-pallet[data-color="Yellow-Light"] {
  background-color: #ebcc37;
}

.color-pallet[data-color="Red-Dark"] {
  border: 3.1px solid black;
  background-color: rgb(194, 2, 2);
}
.color-pallet[data-color="Blue-Dark"] {
  border: 3.1px solid black;
  background-color: rgb(34, 101, 247);
}
.color-pallet[data-color="Green-Dark"] {
  border: 3.1px solid black;
  background-color: rgb(16, 221, 95);
}
.color-pallet[data-color="Purple-Dark"] {
  border: 3.1px solid black;
  background-color: #9350e9;
}
.color-pallet[data-color="Orange-Dark"] {
  border: 3.1px solid black;
  background-color: rgb(248, 122, 19);
}
.color-pallet[data-color="Azure-Dark"] {
  border: 3.1px solid black;
  background-color: rgb(18, 193, 247);
}
.color-pallet[data-color="Grey-Dark"] {
  border: 3.1px solid black;
  background-color: #373737;
}
.color-pallet[data-color="Leaf-Dark"] {
  border: 3.1px solid black;
  background-color: #15846d;
}

.settings_row {
  background-color: var(--background-sec);
  margin: 5px 20px;
  border-radius: 10px;
  color: var(--text);
}
.settings_row[data-color="Dark"] {
  background-color: #1a1b26;
}

// .cus_heading[head-color="White"] {
//   color: white;
// }

// .cus_heading[head-color="Black"] {
//   color: #333;
// }

.cus_heading {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: white;
  padding: 10px;
  top: -10px;
  display: inline-block;
  opacity: 0.6;
  background-color: var(--theme-color);
}

.cus_data[head-color="White"] {
  color: white;
}

.cus_data[head-color="Black"] {
  color: #333;
}
