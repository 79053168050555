#deviceIdSelect {
  background-color: #e9a14d;
  border: none;
  outline: none;
  width: 100px;
}

span.device-id {
  display: inline-block;
  width: 120px;
}

@media (max-width: 767px) {
  span.company-name {
    font-size: 0.6rem;
    display: inline-block;
  }
  span.device-id {
    width: 82px;
  }

  #deviceIdSelect {
    width: 70px;
  }
}


#showLastUpdateTime{
  font-size: clamp(8px, 2vw, 16px);
}