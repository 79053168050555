.date-box {
  background-color: transparent;
  border: none;
  outline: none;
  width: 145px;
}

.date-wrapper {
  background-color: #4b5dff22;
  color: var(--theme-color);
  padding: 5px 10px;
  margin: 5px;
  border-radius: 10px;
  z-index: 5;
  margin-bottom: 10px;
  position: relative;
  bottom: -15px;
  border: 2px solid var(--theme-color);
  /* margin: 15px; */
  /* box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3),
    -3px -3px 8px rgba(118, 168, 255, 0.2); */
  width: 50%;
}
.neu-date-wrapper {
  background-color: transparent;
  color: var(--theme-color);
  padding: 10px 20px;
  margin: 5px;
  border-radius: 0.5vw;
  z-index: 5;
  margin-bottom: 10px;
  position: relative;
  bottom: -15px;
  /* border: 2px solid #4b5dff; */
  /* margin: 15px; */
  box-shadow: inset 2px 2px 5px #d8e1f9,
    inset -2px -2px 5px rgba(255, 255, 255, 0.9);
  width: 50%;
}

@media (max-width: 768px) {
  .date-box {
    width: 120px;
  }
}
