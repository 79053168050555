.nav-wrapper .nav-firmName {
  text-align: left;
  font-size: 2.2rem;
  font-weight: bold;
  margin-left: 10px;
  color: var(--text);
}

.nav-wrapper .nav-address {
  text-align: left;
  font-size: 1.2rem;
  /* font-weight: bold; */
  margin-left: 10px;
  color: #888;
}

.tooltiptext {
  position: relative;
  visibility: hidden;
  width: 420px;
  background-color: #fff6;
  color: #222;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  border: 1px solid #fff;
  font-size: 1.2rem;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  backdrop-filter: blur(7px);
}

.nav-address:hover + .tooltiptext {
  visibility: visible;
}

.companyLogo {
  transform: translateY(-25%);
  display: inline-block;
}
.companyInfo {
  display: inline-block;
}

@media (max-width: 768px) {
  .companyInfo {
    width: 70%;
  }

  .companyInfo .nav-firmName {
    font-size: 1.5rem;
  }

  .companyInfo .nav-address {
    font-size: 0.8rem;
  }

  .companyInfo .tooltiptext {
    width: 70%;
    display: inline-block;
  }

  .companyLogo {
    transform: translateY(-50%);
    display: inline-block;
  }
}
