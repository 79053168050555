.wrapperNoDevice {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(154, 164, 255);
}
.noDeviceBody {
  margin: 10px;
  border: 2px solid rgb(154, 164, 255);
  background-color: rgba(154, 164, 255, 0.3);
  box-sizing: border-box;
  display: inline-block;
  border-radius: 10px;
  width: auto;
}

/* .wrapperNoDevice {
  width: 100%;
  height: 100vh;
} */

.notFoundIcon {
  font-size: 12rem;
}

.ManageDeviceLink {
  display: inline-block;
  font-size: 18px;
  padding: 5px;
  text-decoration: underline;
  font-weight: bold;
  color: rgb(154, 164, 255);
  margin: 0 !important;
}

.NotAddedTitle {
  display: inline-block;
  padding: 5px;
  /* width: 60%; */
}

@media (max-width: 991px) {
  .NotAddedTitle {
    width: 100%;
  }
}
