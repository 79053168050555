.profile-cover-image {
  position: relative;
  margin: 30px;
  height: 200px;
  //   min-height: 500px;
  border-radius: 10px;
  // background: #ff5085;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  //   clip-path: circle(20% at 50% 90%);

  //   background-position: bottom;
  //   width: 40%;
}

.profile {
  text-align: center;
  overflow: hidden;
}

.profile-logo-container {
  //   padding: 6px;
  position: relative;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: 170px;
  //   height: 140px;
  border-radius: 40px;
  background-color: #fff4;
  border: 1px solid #ffffff99;
  box-shadow: 0px 5px 10px #a4c5f844;
  backdrop-filter: blur(5px);
  padding: 5px;
  //   opacity: 0;
}

.profile-user-info {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  margin: -100px 50% 30px 50% !important;
  transform: translateX(-50%);
  border-radius: 10px;
  min-width: 100px;

  width: 900px;

  background-color: transparent;
  //   box-shadow: 5px 5px 10px rgba(106, 122, 165, 0.3), -5px -5px 10px #fff;
}
.profile-service-info {
  margin: 50px 50% 30px 50% !important;
  transform: translateX(-50%);
  border-radius: 10px;
  min-width: 100px;
  min-height: 200px;
  width: 900px;
  text-align: left;
  background-color: transparent;
}

.profile-user-info p,
.profile-service-info p {
  text-align: left;
  color: rgb(137, 153, 187);
  font-weight: bold;
}

.profile-user-card {
  text-align: left;
  color: var(--theme-color);
  position: relative;
  background-color: transparent;
  min-height: auto !important;
}

.profile-user-card i {
  color: var(--theme-color);
}
.profile-user-card .profile-firm-name {
  text-align: left;
  font-size: 2.4rem;
  font-weight: bold;
  border-bottom: 2px solid rgba(196, 227, 255, 0.6);
  padding-bottom: 10px;
  color: var(--theme-color);
  margin-bottom: 30px !important;
}

.profile-user-name {
  margin-left: 10px;
  font-family: "century gothic";
  color: #444;
  font-weight: bold;
  color: var(--theme-color);
  font-size: 1.3em;
}

.profile-user-mail,
.profile-user-phone,
.profile-user-address {
  margin-left: 10px;
  font-family: "century gothic";
  font-weight: normal;
  color: var(--theme-color);
  margin-top: 5px;
}

.profile-user-address {
  width: 70%;
  margin-top: 5px;
}

.circular-button {
  text-align: center;
  padding: 10px;
  border: none;
  outline: none;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: var(--theme-color);
  box-shadow: 5px 5px 10px rgba($color: var(--theme-color), $alpha: 0.7);
  color: white !important;
  border-radius: 30px;
  font-size: 0rem;
  //   box-shadow: 2px 2px 5px #62769644;
  //   border-right: 2px dashed #e6effe;
  z-index: 15;
  transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-family: "century Gothic";
  overflow: hidden;

  & i {
    font-size: 2.2rem;
    color: white;
    padding: 5px;
  }

  &:hover {
    // box-shadow: none;
    transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 120px;
    font-size: 1.6rem;
    box-shadow: none;
    height: 40px;
    padding: 5px;
    // border: 4px solid #80adf3;
  }

  &:hover > i {
    font-size: 1.6rem;
    padding-right: 10px;
  }

  &:active {
    transform: scale(0.95);
  }
}

.profile-addProfilePic,
.profile-phoneVerify,
.profile-changePassButton,
.profile-phoneAdd {
  border: none;
  color: white;
  outline: none;
  padding: 5px 15px;
  border-radius: 20px;
  display: inline-block;
  margin: 10px;
  font-family: century gothic;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  z-index: 20;
}

.profile-changePassButton {
  background-color: #c345fe;
  box-shadow: 5px 5px 10px #f845fe44;
}

.profile-addProfilePic {
  background-color: #fe8d45;
  box-shadow: 5px 5px 10px #fe8d4544;
  margin-left: -100px;
}
.profile-phoneAdd {
  background-color: #ffca37;
  box-shadow: 5px 5px 10px #feeb4544;
}

.profile-phoneVerify {
  background-color: #22bc96;
  box-shadow: 5px 5px 10px #22bc9644;
}

.profile-phoneVerify:hover,
.profile-changePassButton:hover,
.profile-addProfilePic:hover,
.profile-phoneAdd:hover {
  box-shadow: none;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.profile-phoneVerify:active,
.profile-changePassButton:active,
.profile-addProfilePic:active,
.profile-phoneAdd:active {
  transform: scale(0.98);
}

@media (max-width: 1800px) {
  .profile-user-info,
  .profile-service-info {
    width: 600px;
  }
}
@media (max-width: 938px) {
  .profile-user-info,
  .profile-service-info {
    width: 500px;
  }
}

@media (max-width: 538px) {
  .profile-user-info,
  .profile-service-info {
    width: 00px;
  }
}

@media (max-width: 438px) {
  .profile-user-info,
  .profile-service-info {
    width: 300px;
  }
}
