.radio_con_units {
  display: inline-block;
  background: #fff;
  width: auto;
  margin: 5px;
  border-top: 2px solid #ebefff;
  box-shadow: 0 2px 8px rgba(#000, 0.2);
  overflow: hidden;
  position: relative;
  font-size: 0;
  text-align: left;
  white-space: nowrap;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;

  &:hover > .current {
    color: #000 !important;
  }
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    background: #212431;
    height: 100%;
    width: 10%;
    font-size: 1.2rem;
    min-width: 4.25em;
    z-index: 3;
    transition: 0.3s;
  }
}

.unit {
  font-family: "Comfortaa", cursive;
  width: 30%;
  min-width: 4.25em;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.75em 1em;
  cursor: pointer;
  position: relative;
  z-index: 5;
  transition: 0.3s;
  &:nth-child(1).current ~ .slider {
    transform: translateX(0);
  }
  &:nth-child(2).current ~ .slider {
    transform: translateX(120%);
  }
  &:nth-child(3).current ~ .slider {
    transform: translateX(220%);
  }

  &:nth-child(1):hover ~ .slider {
    transform: translateX(0);
  }
  &:nth-child(2):hover ~ .slider {
    transform: translateX(100%);
  }
  &:nth-child(3):hover ~ .slider {
    transform: translateX(200%);
  }

  &:hover {
    color: #fff;
  }
  &.current {
    color: #fff;
    &:hover {
      color: #fff !important;
    }
  }
}
