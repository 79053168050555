.errorMsg {
  position: absolute;
  top: 50px;
  right: 50px;
  // transform: translateX(-50%);
  background-color: crimson;
  color: white;
  padding: 10px 20px;
  text-align: center;
  z-index: 25;
  border-radius: 5px;
  opacity: 0.8;
  text-transform: capitalize;
}

.successMsg {
  position: absolute;
  top: 50px;
  right: 50px;
  // transform: translateX(-50%);
  background-color: #129b7b;
  color: white;
  padding: 10px 20px;
  text-align: center;
  z-index: 25;
  border-radius: 5px;
  opacity: 0.8;
  text-transform: capitalize;
}

.mdDelDev {
  margin: 10px 0 10px 5px;
  background-color: --var(--background);
  padding: 10px 15px;
  box-shadow: 2px 2px 5px var(--box-shadow), -2px -2px 5px var(--box-shadow-inv);
  border-radius: 5px;
}

.mdDelDev select {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
}

.mdDelDev[theme="dark"] {
  background-color: transparent;
  box-shadow: 5px 5px 5px #16161e, -5px -5px 10px #1e202e;
}

.mdDelDev[theme="dark"] select {
  color: #0072bc;
  background-color: transparent;
  // box-shadow: 5px 5px 5px #16161e, -5px -5px 10px #1e202e;
}
