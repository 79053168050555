.map-search {
  position: relative;
}

.map-search > div > input {
  border: none;
  border-radius: 5px;
  background-color: var(--background-sec);
  backdrop-filter: blur(4px);
  color: rgb(97, 97, 97);
  padding: 10px 15px;
  width: 50%;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 20;
  border: 2px solid var(--theme-color);
  /* box-shadow: 5px 5px 10px #98a8e288, -5px -5px 10px #fff; */
}

.map-search > div > input:focus {
  border: 2px solid var(--theme-color);
  box-shadow: 2px 2px 10px #7a9fff44, 10px 10px 20px #7a9fff44,
    -2px -2px 5px #7a9fff44, -10px -10px 20px #7a9fff44;
}

.getCurrentLocation {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 4rem;
  right: 4rem;
  background: none;
  border: none;
  z-index: 10;
}

.getCurrentLocation i {
  font-size: 2.5rem;
  color: white;
}
